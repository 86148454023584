import * as React from 'react'
import Layout from '../components/layout'
import HeroFeatures from '../components/hero-features'
import PartnerBadges from '../components/partner-badges'
import ContactPanel from '../components/contact-panel'

const IndexPage = () => {
  return (
    <Layout pageTitle="Helygan Digital">
      <HeroFeatures />
      <PartnerBadges />
      <ContactPanel />
    </Layout>
  )
}

export default IndexPage
