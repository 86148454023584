import * as React from 'react'
import vendrPartnerLogo from '../images/vendr_registered_partner.png'
import ucommPartnerLogo from '../images/ucommerce-silver-partner-logo.svg'
import ucommLogo from '../images/ucommerce.png'
import umbracoPartnerLogo from '../images/umb-registered-partner.png'

export default function PartnerBadges() {
  return (
    <div className="bg-white">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:py-6 lg:px-8">
            <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">Our Partnerships</p>
            <div className="mt-6 grid grid-cols-1 gap-0.5 md:grid-cols-3 lg:mt-8">
                <div className="col-span-1 flex justify-center py-4 px-6 bg-gray-100">
                    <a href="https://umbraco.com/" className="text-center" target="_blank">
                        <img alt="Umbraco Registered Partner Logo" className="max-h-16 p-1" src={umbracoPartnerLogo} />
                    </a>
                </div>
                <div className="col-span-1 flex justify-center py-6 sm:py-4 px-6 bg-gray-100">
                    <a href="https://ucommerce.net/" className="text-center" target="_blank">
                        <img alt="uCommerce Silver Partner Logo" className="max-h-16 w-3/12 hidden sm:inline-block" src={ucommPartnerLogo} />
                        <img alt="" className="max-h-16 w-6/12 sm:w-4/12 inline-block" src={ucommLogo} />
                    </a>
                </div>
                <div className="col-span-1 flex justify-center py-4 px-6 bg-gray-100">
                    <a href="https://vendr.net/" target="_blank">
                        <img alt="Vendr Registered Partner Logo" className="max-h-16" src={vendrPartnerLogo} />
                    </a>
                </div>
            </div>

            <div className='max-w-5xl mx-auto pt-6'>
                <p className="text-center text-base text-gray-600">
                    <strong>Considering refining the aesthetic and experience of your website or application? </strong>
                </p>
                <p className="text-center text-base text-gray-600">
                    <span>Extending beyond development, we also work with a range of partners to offer support in UX, Design, and SEO.</span>
                </p>
            </div>

        </div>
    </div>
  )
}
