import React from 'react';
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import ContactImage from '../images/contact-us.jpeg'

export default function ContactPanel() {
    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <div className="bg-hely-slate rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
                    <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                    <div className="lg:self-center">
                        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                        <span className="block">Want to know more?</span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-white">
                        Do you have an Umbraco project that needs support? If so, contact us below.
                        </p>
                        <Link
                        to="/contact"
                        className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-white bg-hely-orange hover:bg-hely-orangehover"
                        >
                        Contact Us
                        </Link>
                    </div>
                    </div>
                    <div className="hidden lg:inline -mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
                    <StaticImage src="../images/contact-us.jpeg" alt="" 
                        placeholder="blurred"
                        className="transform translate-x-6 translate-y-6 rounded-r-md opacity-30 object-cover object-left-top sm:translate-x-16" />
                    </div>
                </div>
            </div>
        </div>
    )
}
