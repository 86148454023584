import * as React from 'react'
import { ShieldCheckIcon, GlobeIcon, AcademicCapIcon, CodeIcon, SparklesIcon } from '@heroicons/react/outline'

const features = [
  {
    name: 'Project Builds & Enhancements',
    description:
      'Priding ourselves on working directly with clients as well as partnering with agencies to build new applications from the ground up, as well as growing existing Umbraco systems.',
    icon: CodeIcon,
  },
  {
    name: 'Master Certified',
    description:
      'Holding Master Certification across multiple Umbraco versions. Building cutting-edge solutions in the latest Umbraco, as well as providing comprehensive support for earlier versions.',
    icon: AcademicCapIcon,
  },
  {
    name: 'Upgrades & Health Checks',
    description:
      'Ensure optimal performance and security for your Umbraco project with expert upgrades, or look for performance recommendations with a comprehensive health check.',
    icon: ShieldCheckIcon,
  },
  {
    name: 'Customisations & Integrations',
    description:
      'Enhance your Umbraco application with custom features and external integrations - no matter how small or complex. Tailoring your platform to meet unique business and digital needs.',
    icon: SparklesIcon,
  }
]

export default function HeroFeatures() {
  return (
    <div className="py-8 bg-white">

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
        <h2 className="text-base text-hely-teal font-semibold tracking-wide uppercase">.Net &amp; Umbraco Development Experts</h2>        
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Crafting Exceptional Umbraco Applications
          </p>
          <p className="mt-4 max-w-3xl text-xl text-gray-500 lg:mx-auto">
          Boasting extensive experience and full certification - delivering award-winning projects that meet the highest standards of quality and performance.
          </p>
        </div>


        <div className="mt-10">

          
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-hely-teal text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}